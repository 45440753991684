// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.commonLi {
  float: left;
  padding: 0 (31rem / 100);
  height: (40rem / 100);
  line-height: (40rem / 100);
  font-size: (18rem / 100);
  text-align: center;
  margin-right: (33rem / 100);
  cursor: pointer;
}

.live-container {
  @extend %out-box;
  background: url('https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg');

  .live-content {
    @extend %inside-box;
    padding-top: (45rem / 100);
    padding-bottom: (160rem / 100);

    // 顶部
    .live-switch-type {
      display: flex;
      margin-bottom: (35rem / 100);

      &-title {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #761F1E;
        line-height: (32rem / 100);
        margin-right: (10rem / 100);
      }

      &-content {
        display: flex;
        flex-wrap: wrap;

        &-li {
          width: (100rem / 100);
          height: (32rem / 100);
          margin: 0 (30rem / 100) (12rem / 100) 0;
          background: url('https://image.bookgo.com.cn/webculture/jm/normal_type_li2.png') no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: (32rem / 100);
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          cursor: pointer;
        }
        .imgType {
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
          no-repeat;
          background-size: 100% 100%;
        }
        &-li-active {
          background: url('https://image.bookgo.com.cn/webculture/jm/activity_type_li.png') no-repeat !important;
          background-size: 100% 100% !important;
          color: #FFFFFF;
          font-weight: 500;
        }
      }
    }
  }
}